<template>
  <div id="recruitComp">
    <header-comp @changeLanguage="changeLanguage" @contactUsData="contactUsData"></header-comp>
    <section class="topBg">
      <h3>{{ lxwm }}</h3>
      <p>{{ gygsfz }}</p>
      <img :src="contactUsBgUrl" :alt="lxwm" class="contactUs" />
    </section>
    <section class="zpxx">
      <h3 class="companyArr" :style="`background:url(${lxwmgsdz}) center no-repeat`" id="gsdz"></h3>
      <div class="firstRow">
        <div class="leftSide">
          <img :src="url4" alt="地图">
        </div>
        <div class="rightSide">
          <strong>{{ gsmc }}</strong>
          <span>{{ sscqcyxgs }}</span>
          <strong>{{ gsdz }}</strong>
          <span>{{ bjcysf }}</span>
        </div>
      </div>
      <h3 class="swqt" :style="`background:url(${lxwmswqt}) center no-repeat`" id="swqt"></h3>
      <div class="secondRow">
        <div class="leftSide">
          <strong>{{ mfycgf }}</strong>
          <span><i class="fas me-3 text-primary fa-fw fa-mobile"></i>400-080-7727</span>
          <span><i class="fas me-3 text-primary fa-fw fa-envelope"></i>develop@tidycar.cn</span>
        </div>
        <div class="rightSide">
          <img :src="url2" alt="">
          <span></span>
        </div>
      </div>
    </section>
    <section class="hyjrwm">
      <strong>{{ hyjrwm }}</strong>
      <span>{{ qlx }}：develop@tidycar.cn</span>
      <img :src="url3" alt="欢迎加入我们">
    </section>
    <section class="rlzp" id="rlzp">
      <div class="block" v-for="item in recruitList" :key="item.id">
        <div class="title">
          <img :src="url5" alt="ico">
          <span>{{ item.title }}</span>
        </div>
        <div class="content">
          <p>
            <strong>{{ gzdd }}</strong>
            <span>{{ item.address }}</span>
          </p>
          <p>
            <strong>{{ gznx }}</strong>
            <span>{{ item.workingYears }}</span>
          </p>
          <p>
            <strong>{{ xzfw }}</strong>
            <span>{{ item.salaryRange }}</span>
          </p>
          <p>
            <strong>{{ xlyq }}</strong>
            <span>{{ item.education }}</span>
          </p>
          <p>
            <strong>{{ gwms }}</strong>
            <span>{{ item.description }}</span>
          </p>
        </div>
      </div>
    </section>
    <footer-comp></footer-comp>
  </div>
</template>

<script type="text/javascript">
import headerComp from '@/views/components/headerComp'
import footerComp from '@/views/components/footerComp'
import { getRecruitList } from '@/api/index'
export default {
  name: "recruitComp",
  components: {
    headerComp, footerComp
  },
  data() {
    return {
      contactUsBgUrl: require('../assets/images/contactUsBg.png'),
      url2: require('../assets/images/img.png'),
      url3: require('../assets/images/hyjrwm.png'),
      url4: require('../assets/images/map.png'),
      url5: require('../assets/images/recruitIco.png'),
      queryParams: {
        languageType: '1',
        enable: '1'
      },
      recruitList: [],
      lxwm: '',
      gygsfz: '',
      lxwmgsdz: '',
      gsmc: '',
      sscqcyxgs: '',
      gsdz: '',
      bjcysf: '',
      lxwmgsdz: '',
      lxwmswqt: '',
      mfycgf: '',
      hyjrwm: '',
      qlx: '',
      gzdd: '',
      gznx: '',
      xzfw: '',
      xlyq: '',
      gwms: ''
    }
  },
  computed: {
  },
  methods: {
    async getData() {
      const result = await getRecruitList(this.queryParams)
      this.recruitList = result.data
    },
    changeLanguage(data) {
      this.queryParams.languageType = data
      this.getData()
    },
    contactUsData(data) {
      if (data && data.length) {
        data.forEach(item => {
          if (item.dataKey === 'lxwm') {
            this.lxwm = item.dataValue
          } else if (item.dataKey === 'gygsfz') {
            this.gygsfz = item.dataValue
          } else if (item.dataKey === 'lxwmgsdz') {
            this.lxwmgsdz = item.dataValue
          } else if (item.dataKey === 'gsmc') {
            this.gsmc = item.dataValue
          } else if (item.dataKey === 'sscqcyxgs') {
            this.sscqcyxgs = item.dataValue
          } else if (item.dataKey === 'gsdz') {
            this.gsdz = item.dataValue
          } else if (item.dataKey === 'bjcysf') {
            this.bjcysf = item.dataValue
          } else if (item.dataKey === 'lxwmgsdz') {
            this.lxwmgsdz = item.dataValue
          } else if (item.dataKey === 'lxwmswqt') {
            this.lxwmswqt = item.dataValue
          } else if (item.dataKey === 'mfycgf') {
            this.mfycgf = item.dataValue
          } else if (item.dataKey === 'hyjrwm') {
            this.hyjrwm = item.dataValue
          } else if (item.dataKey === 'qlx') {
            this.qlx = item.dataValue
          } else if (item.dataKey === 'gzdd') {
            this.gzdd = item.dataValue
          } else if (item.dataKey === 'gznx') {
            this.gznx = item.dataValue
          } else if (item.dataKey === 'xzfw') {
            this.xzfw = item.dataValue
          } else if (item.dataKey === 'xlyq') {
            this.xlyq = item.dataValue
          } else if (item.dataKey === 'gwms') {
            this.gwms = item.dataValue
          }
        })
      }
    }
  },
  created() {
  },
  mounted() {
    this.getData()
  },
}
</script>

<style lang="less" scoped>
@import '../assets/css/style.default.css';
@import '../assets/css/custom.css';

#recruitComp {
  position: relative;
  width: 100%;
}

@media (min-width:992px) {
  #recruitComp {
    position: relative;
    padding-top: 182px;
    width: 100%;
  }
}

.topBg {
  width: 100%;
  height: 240px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  img.contactUs {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 65% 100%;
  }

  @media (min-width:992px) {
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h3,
  p {
    position: absolute;
    left: 50px;
  }

  h3 {
    font-size: 32px;
    top: 82px;
  }

  p {
    font-size: 16px;
    top: 138px;
  }

  @media (min-width:992px) {

    h3,
    p {
      position: absolute;
      left: 360px;
    }

    h3 {
      font-size: 32px;
      top: 82px;
    }

    p {
      font-size: 16px;
      top: 138px;
    }
  }
}

.zpxx {
  min-height: 500px;
  max-width: 1200px;
  height: 100%;
  width: 100%;
  margin: 0 auto;

  h3.companyArr,
  h3.swqt {
    display: block;
    text-align: center;
    margin: 80px auto 40px;
    min-width: 126px;
    height: 40px;
    // background: url('../assets/images/companyArr.png') no-repeat;
    background-size: 100% 100%;
  }

  h3.swqt {
    margin-top: 80px;
    // background: url('../assets/images/swqt.png') no-repeat;
    background-size: 100% 100%;
  }

  .firstRow {
    margin-top: 40px;
    max-width: 1200px;
    width: 100%;

    .leftSide,
    .rightSide {
      width: 100%;
      height: auto;

      img {
        width: 100%;
      }
    }

    .rightSide {
      background: linear-gradient(135deg, #FFFFFF 0%, #FEF9D9 100%);
      border-radius: 0px 8px 8px 0px;
      padding: 20px;
      box-sizing: border-box;

      strong,
      span {
        display: block;
        font-size: 24px;
        color: #333;
      }

      strong {
        margin-top: 20px;

        &:first-child {
          margin-top: 20px;
        }
      }

      span {
        font-size: 16px;
        margin-top: 10px;
      }
    }
  }

  @media (min-width:992px) {
    .firstRow {
      margin-top: 40px;
      display: flex;
      flex-wrap: wrap;
      max-width: 1200px;
      width: 100%;

      .leftSide,
      .rightSide {
        width: 50%;
        height: 312px;
      }

      .rightSide {
        background: linear-gradient(135deg, #FFFFFF 0%, #FEF9D9 100%);
        border-radius: 0px 8px 8px 0px;

        strong,
        span {
          display: block;
          font-size: 24px;
          color: #333;
        }

        strong {
          margin-top: 32px;

          &:first-child {
            margin-top: 80px;
          }
        }

        span {
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
  }

  .secondRow {
    margin-top: 30px;
    margin-bottom: 30px;
    max-width: 1200px;
    width: 100%;
    background: linear-gradient(135deg, #FFFFFF 0%, #FEF9D9 100%);
    border-radius: 0.417vw;

    .leftSide {
      width: 100%;
      padding: 20px;

      strong,
      span {
        display: block;
        font-size: 24px;
        color: #333;
      }


      span {
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .rightSide {
      position: relative;
      width: 100%;
      height: 100%;

      span {
        display: none;
      }

      img {
        width: 100%;
        height: 200px;
      }
    }
  }

  @media (min-width:992px) {
    .secondRow {
      margin-top: 154px;
      margin-bottom: 80px;
      max-width: 1200px;
      width: 100%;
      height: 11.771vw;
      display: flex;
      background: linear-gradient(135deg, #FFFFFF 0%, #FEF9D9 100%);
      border-radius: 0.417vw;

      .leftSide {
        width: 50%;

        strong,
        span {
          display: block;
          font-size: 24px;
          color: #333;
        }

        strong {
          margin-top: 32px;

          &:first-child {
            margin-top: 80px;
          }
        }

        span {
          font-size: 16px;
          margin-top: 10px;
        }
      }

      .rightSide {
        position: relative;
        width: 50%;
        height: 100%;

        span {
          position: absolute;
          display: block;
          width: 80px;
          height: 80px;
          background: #FADB14;
          border-radius: 8px;
          right: 0;
          bottom: 0;
          z-index: 2;
        }

        img {
          position: absolute;
          right: 0;
          bottom: 40px;
          max-width: 600px;
          width: 100%;
          height: 15.625vw;
        }
      }
    }
  }
}

.hyjrwm {
  position: relative;
  width: 100%;

  img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }

  strong,
  span {
    position: absolute;
    left: 20px;
    top: 40%;
    transform: translate(0, -50%);
    color: #262626;
  }

  strong {
    font-size: 32px;
  }

  span {
    top: 60%;
    font-size: 16px;
  }
}

@media (min-width:992px) {
  .hyjrwm {
    position: relative;
    width: 100%;

    img {
      width: 100%;
      height: 320px;
    }

    strong,
    span {
      position: absolute;
      left: 1023px;
      color: #262626;
      transform: translate(0, 0);
    }

    strong {
      top: 118px;
      font-size: 32px;
    }

    span {
      top: 182px;
      font-size: 16px;
    }


  }
}

.rlzp {
  min-height: 450px;
  max-width: 1200px;
  height: 100%;
  width: 100%;
  margin: 20px auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .block {
    width: calc(100% - 16px);
    min-height: 432px;
    margin: 0 auto 20px;

    .title {
      width: 100%;
      white-space: nowrap;
      height: 78px;
      line-height: 78px;
      font-size: 24px;
      padding: 0 24px;
      box-sizing: border-box;
      background: linear-gradient(270deg, rgba(250, 219, 20, 0) 0%, #FADB14 100%);

      span {
        margin-left: 8px;
      }
    }

    .content {
      width: 100%;
      padding: 16px 24px;
      box-sizing: border-box;
      background: #F5F5F5;

      p {

        strong,
        span {
          display: block;
          width: 100%;
        }

        strong {
          font-size: 18px;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }

  @media (min-width:992px) {
    .block {
      width: 580px;
      min-height: 432px;
    }
  }
}

@media (min-width:992px) {
  .rlzp {
    margin: 40px auto;
    .block{
      margin: 0 0 20px;
    }
  }
}
</style>
